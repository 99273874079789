import RAF from "../utils/RAF"

class SoundReactor {

    constructor() {
        this.ctx
        this.audio
        this.audioSource
        this.analyser
        this.fdata
        this.playFlag = false
        this.bind()
    }

    init() {
        if (this.initFlag) return
        this.initFlag = true
        this.ctx = new AudioContext();
        this.audio = new Audio("assets/on-my-side.mp3");
        this.audio.currentTime = 45
        this.audioSource = this.ctx.createMediaElementSource(this.audio);
        this.analyser = this.ctx.createAnalyser();
        this.analyser.smoothingTimeConstant = 0.8

        this.audioSource.connect(this.analyser);
        this.audioSource.connect(this.ctx.destination);
        this.fdata = new Uint8Array(this.analyser.frequencyBinCount);
    }

    play() {
        this.audio.play()
        this.playFlag = true
        RAF.subscribe('fftUpdate', this.update)
    }

    pause() {
        this.audio.pause()
        this.playFlag = false
        RAF.unsubscribe('fftUpdate')
    }

    update() {
        this.analyser.getByteFrequencyData(this.fdata);
    }

    bind() {
        this.update = this.update.bind(this)
        this.init = this.init.bind(this)
    }

}
const _instace = new SoundReactor()
export default _instace;