<template>
    <a class="uiLink" :href="link" target="_blank" ref="link">
        <span class="uiLink_fill"></span>{{ text }}</a
    >
</template>

<script>
export default {
    name: "LoadingScreen",
    props: {
        text: null,
        link: null,
    },
    mounted() {
        this.$refs.link.addEventListener("mouseenter", this.linkIn);
        this.$refs.link.addEventListener("mouseleave", this.linkOut);
    },
    methods: {
        linkIn: function () {
            this.$refs.link.classList.add("on");
        },
        linkOut: function () {
            this.$refs.link.classList.remove("on");
        },
    },
};
</script>

<style scoped lang="stylus">
.uiLink {
    position: relative;

    &_fill {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        height: 2px;
        background: white;
        opacity: 1;
        transition: all 0.2s;
    }

    &.on {
        color: #bc13fe;

        .uiLink_fill {
            height: 100%;
            opacity: 0;
        }
    }
}
</style>
