import * as THREE from "three"
import vertSource from "../shaders/outerSphere.vert"
import fragSource from "../shaders/outerSphere.frag"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import RAF from "../utils/RAF"
import MyGUI from "../utils/MyGUI"
import texturesLib from "../utils/textureLib"
import loadingManager from "./loadingManager"

class OuterSphere {
    constructor() {
        this.bind()
    }

    init(scene) {
        this.scene = scene
        this.texLoader = new THREE.TextureLoader(loadingManager)
        this.modelLoader = new GLTFLoader(loadingManager)
        this.shell

        const uniforms = {
            uM1: {
                value: texturesLib.find(tex => tex.name == "shiny-black-metal").texture
            },
            uTime: {
                value: 0
            },
            uBorderColor: {
                value: new THREE.Color("hsl(287, 80%, 80%)")
            },
            uBorderSize: {
                value: 0.2
            },
            uSpecterSize: {
                value: 0.6
            },
            uSpeed: {
                value: 2
            },
        }

        const shaderFolder = MyGUI.addFolder("Outer radiation shader")
        shaderFolder.open()
        shaderFolder.add(uniforms.uBorderSize, "value", 0, 1).step(0.001).name("Wave border size")
        shaderFolder.add(uniforms.uSpecterSize, "value", -1, 2).step(0.001).name("Wave size")
        shaderFolder.add(uniforms.uSpeed, "value", 0, 5).step(0.001).name("Speed")

        this.shadMat = new THREE.ShaderMaterial({
            vertexShader: vertSource,
            fragmentShader: fragSource,
            uniforms: uniforms,
            transparent: true,
        })

        this.modelLoader.load("assets/models/outerShell.glb", (glb) => {
            glb.scene.traverse(child => {
                if (child instanceof THREE.Mesh)
                    this.shell = child
            })
            this.shell.material = this.shadMat
            this.scene.add(this.shell)
        })

        // this.mesh = new THREE.Mesh(new THREE.IcosahedronGeometry(3, 5), this.shadMat)
        // this.scene.add(this.mesh)s
    }

    update() {
        this.shadMat.uniforms.uTime.value += RAF.dt
    }

    bind() {
        this.update = this.update.bind(this)

    }
}

const _instance = new OuterSphere()
export default _instance