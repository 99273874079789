import * as THREE from "three"
import loadingManager from "../classes/loadingManager"


const texLoader = new THREE.TextureLoader(loadingManager)

const path = "assets/textures/"
const assets = [
    {
        url: path + "shiny-black-metal.png",
        name: "shiny-black-metal"
    },
    {
        url: path + "satin-black-metal.png",
        name: "satin-black-metal"
    },
    {
        url: path + "white-plastic.png",
        name: "white-plastic"
    },
]

let textures = []
assets.forEach(el => {
    const tex = texLoader.load(el.url)
    textures.push({ texture: tex, name: el.name })
});
export default textures