<template>
    <div class="titleCard">
        <div class="titleCard_top">
            <h1>Sound Pillards.</h1>
            <p>A creative coding example for Awwwards academy</p>
        </div>
        <div class="titleCard_bottom">
            <div class="titleCard_bottom_left">
                <p>
                    Modeling and coding by
                    <UILink
                        link="https://mariusballot.com/"
                        text="Marius Ballot"
                    />
                </p>
            </div>
            <div class="titleCard_bottom_right">
                <h3>
                    Music by
                    <UILink
                        link="https://open.spotify.com/artist/2fRT8ojJkJjfr6Z2zNo0WH?si=ldA8M3pbQvqBN6-pOECqaA"
                        text="Adryiano"
                    />
                </h3>
                <p>On My side</p>
                <button class="play" v-if="!playState" @click="onPlay()">
                    Play
                </button>
                <button class="pause" v-if="playState" @click="onPause()">
                    Pause
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SoundReactor from "../classes/SoundReactor";
import UILink from "./UILink";

export default {
    name: "TitleCard",
    components: {
        UILink,
    },
    data() {
        return {
            playState: false,
        };
    },
    props: {
        msg: String,
    },
    mounted() {},
    methods: {
        onPlay() {
            SoundReactor.init();
            SoundReactor.play();
            this.playState = true;
        },

        onPause() {
            SoundReactor.pause();
            this.playState = false;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.titleCard {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 150px;
    position: absolute;
    top: 0;
    left: 0;

    button {
        padding: 10px 30px;
        background: #bc13fe;
        border: none;
        cursor: pointer;
        color: white;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 200px;
        border: solid #bc13fe 2px;
        transition: background 0.3s;

        &:hover {
            background: transparent;
        }

        &:focus {
            outline: none;
        }
    }

    &_top {
        h1 {
            font-size: 4em;
        }
    }

    &_bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;

        &_right {
            text-align: right;

            h3 {
                font-size: 2em;
            }
        }
    }
}
</style>
