import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import loadingManager from "./loadingManager"
import MyGUI from '../utils/MyGUI'

class Base {
    constructor() {

    }
    init(scene) {
        this.scene = scene
        this.modelLoader = new GLTFLoader(loadingManager)
        this.base

        this.modelLoader.load('assets/models/base.glb', (glb) => {
            console.log(glb.scene)
            glb.scene.traverse(child => {
                if (child instanceof THREE.Mesh)
                    this.base = child
            })
            this.base.position.set(0, -3.5, 0)
            this.base.scale.multiplyScalar(2)
            this.scene.add(this.base)
        })
    }

    update() {

    }

    bind() {

    }
}

const _instance = new Base()
export default _instance