import * as THREE from "three"

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import Pillards from "./Pillards"
import Base from "./Base"
import OuterSphere from "./OuterSphere"
import CamParallax from "./CamParallax"
import Particles from "./Particles"

import RAF from '../utils/RAF'
import config from '../utils/config'
import MyGUI from '../utils/MyGUI'

class MainThreeScene {
    constructor() {
        this.bind()
        this.camera
        this.scene
        this.renderer
        this.controls
    }

    init(container) {
        //RENDERER SETUP
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.debug.checkShaderErrors = true
        this.renderer.outputEncoding = THREE.sRGBEncoding
        container.appendChild(this.renderer.domElement)

        //MAIN SCENE INSTANCE
        this.scene = new THREE.Scene()
        const near = 40;
        const far = 60;
        const color = 0x151515;
        this.scene.fog = new THREE.Fog(color, near, far);
        this.scene.background = new THREE.Color(color);

        //CAMERA AND ORBIT CONTROLLER
        this.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 1000)
        this.camera.position.set(0, 0, 25)
        this.controls = new OrbitControls(this.camera, document.querySelector('#app'))

        this.controls.enabled = config.controls
        this.controls.maxDistance = 50
        this.controls.minDistance = 3

        this.controls.maxPolarAngle = Math.PI / 2


        Particles.init(this.scene)
        Base.init(this.scene)
        Pillards.init(this.scene)
        OuterSphere.init(this.scene)
        CamParallax.init(this.camera)

        MyGUI.hide()
        if (config.myGui)
            MyGUI.show()

        const camGUI = MyGUI.addFolder('camera')
        camGUI.open()
        camGUI.add(this.controls, "enabled").name("Orbit cam on").onChange(() => {
            if (this.controls.enabled)
                CamParallax.active = false
        }).listen()
        camGUI.add(CamParallax, "active").name("Parallax on").onChange(() => {
            if (CamParallax.active)
                this.controls.enabled = false
        }).listen()
        camGUI.add(CamParallax.params, "ease", 0.001, 1).step(0.001).name('Parallax easing')
        camGUI.add(CamParallax.params, "intensity", 0, 0.02).step(0.001).name('Parallax intensity')

        //RENDER LOOP AND WINDOW SIZE UPDATER SETUP
        window.addEventListener("resize", this.resizeCanvas)
        RAF.subscribe('threeSceneUpdate', this.update)
    }

    update() {
        Particles.update()
        Pillards.update()
        OuterSphere.update()
        CamParallax.update()
        this.scene.rotateY(0.0001 * RAF.dt)
        this.renderer.render(this.scene, this.camera);
    }

    resizeCanvas() {
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
    }

    bind() {
        this.resizeCanvas = this.resizeCanvas.bind(this)
        this.update = this.update.bind(this)
        this.init = this.init.bind(this)
    }
}

const _instance = new MainThreeScene()
export default _instance