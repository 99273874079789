<template>
    <div class="loadingScreen loading" ref="container">
        <h2>Loading</h2>
        <p class="loading_undertitle">Loading different textures and models</p>
        <div class="loading_bar">
            <div
                class="loading_bar_fill"
                :style="{ width: progress + '%' }"
            ></div>
        </div>
        <p class="loading_loaded">{{ loaded }}</p>
        <p class="loading_progress">{{ progress }} %</p>
    </div>
</template>

<script>
import loadingManager from "../classes/loadingManager";
import MyGUI from "../utils/MyGUI";

export default {
    name: "LoadingScreen",
    data() {
        return {
            loaded: null,
            progress: null,
        };
    },
    created() {
        MyGUI.hide();
    },
    mounted() {
        loadingManager.onProgress = this.onProgress;
        loadingManager.onLoad = this.onLoad;
    },
    methods: {
        onProgress: function (url, itemsLoaded, itemsTotal) {
            this.loaded = url;
            this.progress = (itemsLoaded / itemsTotal) * 100;
        },
        onLoad: function () {
            this.$refs.container.classList.add("start");
            MyGUI.show();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.loading {
    background: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s 0.5s;

    &.start {
        opacity: 0;
        pointer-events: none;
    }

    h2 {
        font-size: 2em;
    }

    &_undertitle {
        margin-bottom: 30px;
    }

    &_bar {
        width: 30vw;
        height: 5px;
        border-radius: 100vw;
        background: #090909;
        overflow: hidden;

        &_fill {
            background: #bc13fe;
            height: 100%;
            transition: width 0.3s;
        }
    }

    &_loaded {
        margin-top: 30px;
        color: #555555;
    }

    &_progress {
        text-align: center;
        width: 100%;
        color: #555555;
        position: absolute;
        bottom: 30px;
        left: 0;
    }
}
</style>
