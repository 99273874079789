import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import textureLib from "../utils/textureLib"
import SoundReactor from "./SoundReactor"
import MyGUI from "../utils/MyGUI"
import loadingManager from "./loadingManager"

class Pillards {
    constructor() {
        this.bind()
        this.params = {
            icoSub: 2,
            pillardSize: .42,
            animSpeed: 1,
        }
        this.pillards = new THREE.Group()
    }

    init(scene) {
        this.scene = scene
        this.pillard
        this.modelLoader = new GLTFLoader(loadingManager)

        this.bMatCap = new THREE.MeshMatcapMaterial({
            matcap: textureLib.find(tex => tex.name == "satin-black-metal").texture
        })
        this.wMatCap = new THREE.MeshMatcapMaterial({
            matcap: textureLib.find(tex => tex.name == "shiny-black-metal").texture
        })


        this.modelLoader.load('assets/models/soundPillards.glB', (glb) => {
            glb.scene.traverse(child => {
                if (child instanceof THREE.Mesh) {
                    if (child.name == "base") this.pillard = child
                    else {
                        child.material = this.bMatCap
                    }
                }
            })
            this.pillard.material = this.wMatCap
            this.pillard.children[0].material = this.bMatCap
            this.computePositions()
            const pillardFolder = MyGUI.addFolder("PillardShere")
            pillardFolder.open()
            pillardFolder.add(this.params, "icoSub", 1, 7).name('Icosphere subdivision').step(1).onChange(this.computePositions)
            pillardFolder.add(this.params, "pillardSize", 0.1, 1).name('Pillards size').step(0.001).onChange(this.computePositions)
            pillardFolder.add(this.params, "animSpeed", 0, 4).name('Animation speed').step(0.001)
        })
    }

    computePositions() {

        this.pillards.clear()

        let ico
        this.scene.traverse(child => {
            if (child instanceof THREE.Mesh && child.geometry.name == "ico") {
                ico = child
            }
        })

        if (ico) this.scene.remove(ico)

        const geom = new THREE.IcosahedronGeometry(2, this.params.icoSub)
        geom.name = "ico"

        this.scene.add(new THREE.Mesh(geom, this.bMatCap))

        const posAttr = geom.attributes.position.array
        let pillardPos = []

        for (let i = 0; i < posAttr.length; i += 3) {
            const x = posAttr[i]
            const y = posAttr[i + 1]
            const z = posAttr[i + 2]

            let inUse = false
            pillardPos.forEach(vert => {
                if (x == vert.x && y == vert.y && z == vert.z) {
                    inUse = true
                    return
                }
            });

            if (!inUse) {
                const v3 = new THREE.Vector3(x, y, z)
                pillardPos.push(v3)

                const clone = this.pillard.clone()
                clone.position.copy(v3)
                clone.scale.multiplyScalar(this.params.pillardSize)
                var axis = new THREE.Vector3(0, 1, 0);
                clone.quaternion.setFromUnitVectors(axis, v3.clone().normalize());
                this.pillards.add(clone)
            }
        }
        this.scene.add(this.pillards)

    }

    update() {
        if (SoundReactor.playFlag) {
            let i = 0
            while (i < this.pillards.children.length) {
                this.pillards.children[i].children[0].position.y = (SoundReactor.fdata[i % SoundReactor.fdata.length] / 255) * 2
                i++
            }
        } else {
            let i = 0
            while (i < this.pillards.children.length) {
                this.pillards.children[i].children[0].position.y = (Math.sin(Date.now() * 0.006 * this.params.animSpeed + this.pillards.children[i].position.x) + 1) * 1
                i++
            }
        }
    }

    bind() {
        this.update = this.update.bind(this)
        this.computePositions = this.computePositions.bind(this)
    }
}

const _instance = new Pillards()
export default _instance