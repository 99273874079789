<template>
    <LoadingScreen />
    <TitleCard />
    <ThreeScene />
</template>

<script>
import ThreeScene from "./components/ThreeScene.vue";
import TitleCard from "./components/TitleCard.vue";
import LoadingScreen from "./components/LoadingScreen.vue";

export default {
    name: "App",
    components: {
        ThreeScene,
        TitleCard,
        LoadingScreen,
    },
};
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
    color: white;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
</style>
